import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StaticImage } from "gatsby-plugin-image";

import about1 from "../images/about-1.webp";
import plasticInjection from "../images/plastic-injection.webp";
import turningMachine from "../images/turning-machine.png";
import Footer from "../components/footer";
import Header from "../components/header";
import NavMenu from "../components/nav-menu";
import RubberPlasticIcon from "../components/svgs/rubber-plastic-icon";
import MachineIcon from "../components/svgs/machine-icon";
import AssemblyIcon from "../components/svgs/assembly-icon";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Button from "../components/button";
import { messages } from "../i18n-translations";

const AboutPage: React.FC = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [navTopMargin, setNavTopMargin] = React.useState(40);
  const header = useRef<HTMLDivElement>(null);

  const breakpoints = useBreakpoint();
  const isScreenXl = breakpoints.xl;
  const isScreenLg = breakpoints.lg;

  useEffect(() => {
    if (header.current) setNavTopMargin(header.current.clientHeight);
  }, [header]);

  const currentYearStringLast2 = new Date().getFullYear().toString().slice(-2);
  const currentYearLast2 = Number(currentYearStringLast2);

  const rubberYears = currentYearLast2 - 2;
  const machiningYears = currentYearLast2 - 8;
  const assemblyYears = currentYearLast2 - 6;

  return (
    <main className="relative z-0 w-screen bg-gray-100">
      <div ref={header} className="fixed top-0 z-110 w-full bg-white">
        <Header
          isNavOpen={isNavOpen}
          onMenuButtonPress={() => setIsNavOpen(!isNavOpen)}
        />
      </div>
      {
        //#region Mobile Nav
      }
      {!isScreenXl && (
        <AnimatePresence>
          {isNavOpen && (
            <div
              className={`fixed flex inset-0 z-101 w-screen h-screen bg-transparent`}
            >
              <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
                className="flex w-full origin-top"
                key="nav"
                style={{ marginTop: navTopMargin }}
              >
                <NavMenu />
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      )}
      {
        //#endregion
      }

      {
        //#region Section 1
      }
      <div style={{ marginTop: navTopMargin }} className="bg-white">
        <div className="relative">
          <div className="z-0 w-full h-full">
            {isScreenLg ? (
              <StaticImage
                src={"../images/about-header.webp"}
                alt="About Us"
                layout="fullWidth"
              />
            ) : (
              <StaticImage
                src={"../images/about-header-md.webp"}
                alt="About Us"
                layout="fullWidth"
              />
            )}
          </div>
          <div className="absolute inset-0 z-0 flex flex-col items-center justify-center text-white bg-black opacity-80">
            <h1 className="text-5xl ">{messages.en.aboutUs}</h1>
          </div>
        </div>

        <div className="w-full pt-10 bg-gray-200 lg:pt-16">
          <div className="px-10 mx-auto lg:px-20 xl:container">
            <div
              id="profile"
              className="flex flex-col items-center justify-center w-full mb-10 md:mb-20 md:flex-row"
            >
              <div className="w-full pb-4 md:pb-0 md:w-5/12">
                <img
                  src={about1}
                  alt="About Jintai"
                  className="w-full h-auto lg:w-auto lg:h-full rounded-xl"
                />
              </div>
              <div className="flex-1">
                <div className="w-full text-center md:pl-10 md:text-left">
                  <div className="mb-6 text-2xl">{messages.en.aboutJintai}</div>
                  <div className="mb-4">{messages.en.aboutJintaiText}</div>
                  <div>{messages.en.aboutJintaiText2}</div>
                  <AnchorLink to="/about#timeline" title="timeline" stripHash>
                    <Button className="py-6 mt-6 text-xl w-80 md:py-4">
                      {messages.en.learnMoreAboutOurHistory}
                    </Button>
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full py-8 bg-white">
            <div className="px-10 mx-auto lg:px-20 xl:container">
              <div className="flex flex-col items-center justify-center w-full md:flex-row-reverse">
                <div className="w-full pb-4 md:pb-0 md:w-5/12">
                  <img
                    src={plasticInjection}
                    alt="About Jintai"
                    className="w-full h-auto lg:w-auto lg:h-full rounded-xl"
                  />
                </div>
                <div className="md:w-7/12 lg:flex-1">
                  <div className="w-full text-center md:pr-10 md:text-left">
                    <div className="mb-6 text-2xl">
                      {messages.en.oneStopSolution}
                    </div>
                    <div className="md:mb-6">
                      {messages.en.oneStopSolutionText}
                    </div>
                    {isScreenLg && (
                      <div className="flex w-full">
                        <AnchorLink
                          to="/services#plasticInjection"
                          title="Plastic Injection"
                          stripHash
                        >
                          <div className="flex items-center w-1/3 pt-6 md:w-full lg:pt-0">
                            <div className="px-4">
                              <RubberPlasticIcon />
                            </div>
                            <div className="flex flex-col items-start text-left">
                              <div className="pb-1 text-blue-600">
                                <span className="pr-1 text-4xl xl:pr-4">
                                  {rubberYears}
                                </span>
                                {messages.en.years}
                              </div>
                              <div className="text-xs">
                                {messages.en.rubberPlasticExperience}
                              </div>
                            </div>
                          </div>
                        </AnchorLink>
                        <AnchorLink
                          to="/services#machining"
                          title="Machining"
                          stripHash
                        >
                          <div className="flex items-center w-1/3 md:w-full">
                            <div className="px-4">
                              <MachineIcon />
                            </div>
                            <div className="flex flex-col items-start text-left">
                              <div className="pb-1 text-blue-600">
                                <span className="pr-1 text-4xl xl:pr-4">
                                  {machiningYears}
                                </span>
                                {messages.en.years}
                              </div>
                              <div className="text-xs">
                                {messages.en.precisionMachiningExperience}
                              </div>
                            </div>
                          </div>
                        </AnchorLink>
                        <AnchorLink
                          to="/services#assembly"
                          title="Assembly"
                          stripHash
                        >
                          <div className="flex items-center w-1/3 md:w-full">
                            <div className="px-4">
                              <AssemblyIcon />
                            </div>
                            <div className="flex flex-col items-start text-left">
                              <div className="pb-1 text-blue-600">
                                <span className="pr-1 text-4xl xl:pr-4">
                                  {assemblyYears}
                                </span>
                                {messages.en.years}
                              </div>
                              <div className="text-xs">
                                {messages.en.assemblyExperience}
                              </div>
                            </div>
                          </div>
                        </AnchorLink>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!isScreenLg && (
                <div className="flex justify-between w-full gap-4 pt-6 mx-auto md:gap-8">
                  <AnchorLink
                    to="/services#plasticInjection"
                    title="Plastic Injection"
                    stripHash
                  >
                    <div className="flex flex-col items-center w-1/3 pt-0 md:flex-row">
                      <div className="pb-2 md:pr-3 md:pb-0">
                        <RubberPlasticIcon width={50} />
                      </div>
                      <div className="flex flex-col items-center text-center md:items-start md:text-left">
                        <div className="pb-1 text-blue-600">
                          <span className="pr-1 text-xl md:pr-4 md:text-4xl">
                            {rubberYears}
                          </span>
                          {messages.en.years}
                        </div>
                        <div className="text-xs">
                          {messages.en.rubberPlasticExperience}
                        </div>
                      </div>
                    </div>
                  </AnchorLink>
                  <AnchorLink
                    to="/services#machining"
                    title="Machining"
                    stripHash
                  >
                    <div className="flex flex-col items-center w-1/3 pt-0 md:flex-row">
                      <div className="pb-2 md:pr-3 md:pb-0">
                        <MachineIcon width={50} />
                      </div>
                      <div className="flex flex-col items-center text-center md:items-start md:text-left">
                        <div className="pb-1 text-blue-600">
                          <span className="pr-1 text-xl md:pr-4 md:text-4xl">
                            {machiningYears}
                          </span>
                          {messages.en.years}
                        </div>
                        <div className="text-xs">
                          {messages.en.precisionMachiningExperience}
                        </div>
                      </div>
                    </div>
                  </AnchorLink>
                  <AnchorLink
                    to="/services#assembly"
                    title="Assembly"
                    stripHash
                  >
                    <div className="flex flex-col items-center w-1/3 pt-0 md:flex-row">
                      <div className="pb-2 md:pr-3 md:pb-0">
                        <AssemblyIcon width={50} />
                      </div>
                      <div className="flex flex-col items-center text-center md:items-start md:text-left">
                        <div className="pb-1 text-blue-600">
                          <span className="pr-1 text-xl md:pr-4 md:text-4xl">
                            {assemblyYears}
                          </span>
                          {messages.en.years}
                        </div>
                        <div className="text-xs">
                          {messages.en.assemblyExperience}
                        </div>
                      </div>
                    </div>
                  </AnchorLink>
                </div>
              )}
            </div>
          </div>

          <div className="w-full py-8 bg-gray-200">
            <div className="px-10 mx-auto lg:px-20 xl:container">
              <div className="flex flex-col items-center justify-center w-full md:flex-row">
                <div className="w-full pb-4 md:pb-0 md:w-5/12">
                  <img
                    src={about1}
                    alt="About Jintai"
                    className="w-full h-auto lg:w-auto lg:h-full rounded-xl"
                  />
                </div>
                <div className="flex-1">
                  <div className="w-full text-center md:pl-10 md:text-left">
                    <div className="mb-6 text-2xl">
                      {messages.en.designForManufactureAnalysis}
                    </div>
                    <div>{messages.en.designForManufactureAnalysisText}</div>
                    <AnchorLink to="/contact#form" title="Contact Us" stripHash>
                      <Button className="py-6 mt-6 text-xl w-80 md:py-4">
                        {messages.en.contactUs}
                      </Button>
                    </AnchorLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        //#endregion
      }

      {
        //#region Philosophy
      }

      <div className="px-10 mx-auto mt-16 md:px-20 xl:container md:mb-28">
        <div className="text-2xl text-center">
          {messages.en.investmentStrategy}
        </div>

        <div className="flex flex-wrap w-full mb-20">
          <div className="flex justify-center w-full lg:lg:w-1/3">
            <div className="relative z-0 flex items-start w-full mt-10 md:w-5/12 lg:w-11/12">
              <StaticImage
                src={"../images/bg-01.png"}
                alt="jintai"
                objectFit="contain"
                className="w-auto h-full"
              />
              <div className="absolute z-20 flex flex-col items-start justify-start w-full mt-6 text-lg text-left">
                <div>
                  <span className="text-blue-600">01</span>{" "}
                  {messages.en.customerFocused}
                </div>
                <div className="mt-4 text-base">
                  {messages.en.customerFocusedText}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:flex md:justify-around lg:w-2/3">
            <div className="relative z-0 flex items-start w-full mt-8 md:w-5/12">
              <StaticImage
                src={"../images/bg-02.png"}
                alt="jintai"
                objectFit="contain"
                className="w-auto h-full"
              />
              <div className="absolute z-20 flex flex-col items-start justify-start w-full mt-6 text-lg text-left">
                <div>
                  <span className="text-blue-600">02</span>{" "}
                  {messages.en.qualityAndOnTime}
                </div>
                <div className="mt-4 text-base">
                  {messages.en.qualityAndOnTimeText}
                </div>
              </div>
            </div>
            <div className="relative z-0 flex items-start w-full mt-8 md:w-5/12">
              <StaticImage
                src={"../images/bg-03.png"}
                alt="jintai"
                objectFit="contain"
                className="w-auto h-full"
              />
              <div className="absolute z-20 flex flex-col items-start justify-start w-full mt-6 text-lg text-left">
                <div>
                  <span className="text-blue-600">03</span>{" "}
                  {messages.en.continuousImprovement}
                </div>
                <div className="mt-4 text-base">
                  {messages.en.continuousImprovementText}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        //#endregion
      }

      {
        //#region Timeline
      }
      <div id="timeline" className="bg-white">
        <div className="px-20 py-6 mx-auto xl:container md:py-14">
          <div className="mb-10 text-2xl text-center">
            {messages.en.jintaiDevelopment}
          </div>
          <StaticImage
            src={"../images/timeline-1.webp"}
            alt="Jintai Timeline 1"
            layout="fullWidth"
          />
        </div>
      </div>

      {
        //#endregion
      }

      <Footer />
    </main>
  );
};

export default AboutPage;
